<template>
  <empty-state
    :icon="$root.icons.layout.alert"
    class="absolute-center">
    Ops! Ocorreu um erro
  </empty-state>
</template>

<script>
  import EmptyState from '@/components/ui/EmptyState'

  export default {
    components: { EmptyState }
  }
</script>


<style lang="sass">
  .empty-state
    margin-left: -25px
    margin-top: 25px
</style>
